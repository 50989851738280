body {
  background: url(greenlaptop.png) no-repeat center center fixed;
  background-size: cover;
  -webkit-background: cover;
  -moz-background: cover;
  -o-background: cover;
  -webkit-background: cover;
}





