.mainContainer {
  background-color: #233;
  opacity: 1;
  width: 100%;
  position: relative;
  overflow: hidden;
  
  @media (max-width: 512px) {
    .mainContainer {
      padding: 10px; /* Lisää hieman tilaa sivun reunoille pienemmille näytöille */
    }
  
    .timelineItem {
      width: 100%; /* Tee TimelineItem täysin leveä, jotta se sopii puhelimen näytölle */
    }
  
    .timelineOppositeContent {
      display: none; /* Piilota aikajanan vastakkainen sisältö pienillä näytöillä, jotta se mahtuu paremmin */
    }
  
    .timelineDot {
      margin-left: 0px; /* Nollaa marginaali aikajanan pisteille, jotta ne eivät mene sivun ulkopuolelle */
      margin-right: 0px;
    }
  
    .timelineSeparator {
      display: none; /* Piilota aikajanavaraajat pienillä näytöillä */
    }
  }
  
  @media (min-width: 513px) {
    /* Voit lisätä tarvittaessa lisää tyylejä suurille näytöille */
  }  
  
}

